import { sidebarImg } from "./assets/svg/sidebar/index";
import cricket from "./assets/svg/sidebar/cricket.svg";
import blackJack from "./assets/svg/sidebar/black-jack.svg";
import tennis from "./assets/svg/sidebar/tennis.svg";
import navImages from "./assets/svg/navbar";
import {
  insta,
  google,
  faceBookIcon,
  twitter,
} from "assets";
import moment from "moment";
import { getBalanceDetails } from "helper/getIcons";
import { getBetStatus } from "helper/helper";

export const menuData = {
  mainButtons: {
    title: "Main",
    buttons: [
      {
        label: "Casino",
        type: "secondary",
        icon: sidebarImg?.casino,
        path: "/casino-home",
      },
      {
        label: "Sports",
        type: "primary",
        icon: sidebarImg.sports,
        path: "/sports-home",
      },
    ],
  },
  accountButtons: {
    title: "",
    buttons: [
      // { label: "Profile", icon: sidebarImg.profile, path: "/#" },
      // { label: "Wallet", icon: sidebarImg.wallet, path: "/#" },
      // { label: "Transaction history", icon: sidebarImg.history, path: "/#" },
      { label: "My Bets", icon: sidebarImg.bets, path: "/my-bets" },
      // { label: "Affiliate", icon: sidebarImg.affiliate, path: "/#" },
      {
        label: "Responsible Gaming",
        icon: sidebarImg.responsiveGaming,
        path: "/responsible-gaming",
      },
      // { label: "Support", icon: sidebarImg.support, path: "/#" },
      { label: "Logout", icon: sidebarImg.logout, path: "/#" },
    ],
  },
  sportsButtons: {
    title: "All Sports",
    buttons: [
      { label: "Cricket", icon: sidebarImg.cricket, path: "/#" },
      { label: "Football", icon: sidebarImg.football, path: "/#" },
      { label: "Horse Racing", icon: sidebarImg.horseRacing, path: "/#" },
      { label: "Politics", icon: sidebarImg.politics, path: "/#" },
      { label: "Tennis", icon: sidebarImg.tennis, path: "/#" },
      { label: "F1 Race", icon: sidebarImg.f1Race, path: "/#" },
      { label: "Golf", icon: sidebarImg.golf, path: "/#" },
      { label: "Basketball", icon: sidebarImg.basketball, path: "/#" },
      { label: "Baseball", icon: sidebarImg.baseball, path: "/#" },
    ],
  },
  allCasinos: {
    title: "All Casinos",
    buttons: [
      { label: "Black jack", icon: sidebarImg.blackJack, path: "/#" },
      { label: "Roulette", icon: sidebarImg.roulette, path: "/#" },
      { label: "Poker", icon: sidebarImg.poker, path: "/#" },
      { label: "Bacarat", icon: sidebarImg.bacarat, path: "/#" },
      { label: "Deal or no deal", icon: sidebarImg.dealNoDeal, path: "/#" },
      { label: "Teen patti", icon: sidebarImg.teenPatti, path: "/#" },
      {
        label: "Texas hold em poker",
        icon: sidebarImg.texasHold,
        path: "/#",
      },
      { label: "Speed bacarrat", icon: sidebarImg.speedBacarate, path: "/#" },
    ],
  },
};

export const recentPlayed = [
  {
    label: "Cricket",
    icon: cricket,
  },
  {
    label: "Tennis",
    icon: tennis,
  },
  {
    label: "Black jack",
    icon: blackJack,
  },
  {
    label: "Cricket",
    icon: cricket,
  },
  {
    label: "Tennis",
    icon: tennis,
  },
  {
    label: "Black jack",
    icon: blackJack,
  },
  {
    label: "Cricket",
    icon: cricket,
  },
  {
    label: "Tennis",
    icon: tennis,
  },
  {
    label: "Black jack",
    icon: blackJack,
  },
];

export const MbNavTabs = [
  {
    label: "Home",
    icon: navImages.home,
    link: "/",
  },
  {
    label: "My Bets",
    icon: navImages.bets,
    link: "/my-bets",
  },
  {
    label: "Casino",
    icon: navImages.casino,
    link: "/casino-home",
  },
  {
    label: "Sports",
    icon: navImages.sports,
    link: "/sports-home",
  },
  {
    label: "Settings",
    icon: navImages.setting,
    link: "/#",
  },
];

export const footerData = [
  // {
  //   section: "Casino",
  //   links: [
  //     "Black Jack",
  //     "Roulette",
  //     "Poker",
  //     "Baccarat",
  //     "Deal or No Deal",
  //     "Speed Baccarat",
  //     "Teen Patti",
  //     "Texas Hold Em Poker",
  //   ],
  // },
  // {
  //   section: "Sports",
  //   links: [
  //     "Cricket",
  //     "Football",
  //     "Horse Racing",
  //     "Politics",
  //     "Tennis",
  //     "F1 Race",
  //     "Golf",
  //   ],
  // },
  {
    section: "Company",
    links: [
      {
        label : "My Bets",
        path : "/my-bets"
      },
      {
        label : "Affiliate",
        path : "/affiliate-program"
      },
      {
        label : "Responsible Gaming",
        path : "/responsible-gaming"
      },
      {
        label : "Logout"
      }
    ],
  },
  {
    section: "Legal",
    links: [
      {
        label : "Privacy Policy",
        path : "/privacy-policy"
      },
      {
        label : "Terms & Service",
        path : "/terms-service"
      },
      {
        label : "Fair 100% Authentic",
        path : "/fair-authentic"
      },
      {
        label : "FAQ: Deposits & Withdrawls",
        path : "/faq-deposits-withdrawls"
      },
      {
        label : "Provider",
        path : "/provider"
      },
      {
        label : "Withdrawl policy",
        path : "/withdrawl-policy"
      },
      {
        label : "Anti money laundring",
        path : "/anti-money-laundring"
      },
      {
        label : "Sportsbook",
        path : "/sportsbook"
      },
    ],
  },
  {
    section: "Support",
    links: ["Email Us", "WhatsApp on +919997761190"],
  },
];

export const casinoColumns = [
  { header: "GAME", key: "gameName", showOnMobile: true },
  { header: "USER", key: "userName" },
  {
    header: "TIME",
    key: "time",
    render: (value, row) => <span>{moment(value).format("h:mmA")}</span>,
  },
  {
    header: "BET AMOUNT",
    key: "betAmount",
    alignItems: "text-end",
    render: (value, row = {}) => {
      const iconSrc = getBalanceDetails(row?.currencyType)?.icon
      return (
        <div className="flex items-center justify-end space-x-2 ">
          <span>{value}</span>
          {iconSrc && (
            <img
              src={iconSrc}
              alt={row?.currencyType || "USD"}
              className={`w-5 h-5`}
            />
          )}
        </div>
      );
    },
    showOnMobile: true,
  },
  {
    header: "MULTIPLIER",
    key: "multiplier",
    alignItems: "text-end",
    render: (value, row = {}) => {
      return (
        <div className="flex items-center justify-end space-x-2">
          <span>{value}</span>
        </div>
      );
    },
  },
  {
    header: "PAYOUT",
    key: "payOut",
    alignItems: "text-end",
    showOnMobile: true,
    render: (value, row = {}) => {
      const iconSrc = getBalanceDetails(row?.currencyType)?.icon;
      const textColor =
        row?.multiplier > 0 ? "text-green-500" : "text-Amaranth";
      return (
        <div className="flex items-center justify-end space-x-2">
          <span className={textColor}>{value}</span>
          {iconSrc && (
            <img
              src={iconSrc}
              alt={row?.currencyType || "USD"}
              className="w-5 h-5"
            />
          )}
        </div>
      );
    },
  },
];

export const columns = [
  { header: "GAME", key: "gameName", showOnMobile: true },
  { header: "USER", key: "userName" },
  {
    header: "TIME",
    key: "createdAt",
    render: (value, row) => <span>{moment(value).format("h:mmA")}</span>,
  },
  {
    header: "BET AMOUNT",
    key: "amount",
    alignItems: "text-end",
    render: (value, row = {}) => {
      const iconSrc = getBalanceDetails(row?.currencyType)?.icon;
      return (
        <div className="flex items-center justify-end space-x-2">
          <span>{value}</span>
          {iconSrc && (
            <img src={iconSrc} alt={row?.currencyType} className="w-5 h-5" />
          )}
        </div>
      );
    },
    showOnMobile: true,
  },
  {
    header: "MULTIPLIER",
    key: "multiplier",
    alignItems: "text-end",
    render: (value, row = {}) => {
      return (
        <div className="flex items-center justify-end space-x-2">
          <span>{value}</span>
        </div>
      );
    },
  },
  {
    header: "PAYOUT",
    key: "payOut",
    alignItems: "text-end",
    showOnMobile: true,
    render: (value, row = {}) => {
      const iconSrc = getBalanceDetails(row?.currencyType)?.icon;
      const textColor = getBetStatus(row?.betStatus);
      return (
        <div className="flex items-center justify-end space-x-2">
          <span className={textColor}>{value}</span>
          {iconSrc && (
            <img
              src={iconSrc}
              alt={row?.currencyType}
              className="w-5 h-5"
            />
          )}
        </div>
      );
    },
  },
];

export const rows = [
  {
    gameName: "Teen Patti",
    user: "Rakesh007",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "USD",
  },
  {
    gameName: "Poker",
    user: "Ajay007",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "BTC",
  },
  {
    gameName: "Black Jack",
    user: "Deepak",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "USD",
  },
  {
    gameName: "Roulette",
    user: "Alexander",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "USD",
  },
  {
    gameName: "Deal or No Deal",
    user: "Sam Uncle",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "BTC",
  },
  {
    gameName: "Baccarat",
    user: "Samikska",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "USD",
  },
  {
    gameName: "Teen Patti",
    user: "Rajdeep",
    time: "10:30 AM",
    amount: "2812.499859",
    multiplier: "2.00x",
    payOut: "5624.99",
    currencyType: "BTC",
  },
];

export const cardData = [
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "WIN",
    match: "India vs Bangladesh",
    result: "India",
    odds: "1.24",
    betAmount: "10.00",
    payout: "240.00",
  },
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "LOSS",
    match: "India vs Bangladesh",
    result: "India",
    odds: "1.24",
    betAmount: "10.00",
    payout: "0.00",
  },
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "WIN",
    match: "Pakistan vs Australia",
    result: "Pakistan",
    odds: "2.00",
    betAmount: "20.00",
    payout: "400.00",
  },
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "WIN",
    match: "India vs Bangladesh",
    result: "India",
    odds: "1.24",
    betAmount: "10.00",
    payout: "240.00",
  },
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "LOSS",
    match: "India vs Bangladesh",
    result: "India",
    odds: "1.24",
    betAmount: "10.00",
    payout: "0.00",
  },
  {
    date: "12 Sep 2024 at 10:30PM",
    status: "WIN",
    match: "Pakistan vs Australia",
    result: "Pakistan",
    odds: "2.00",
    betAmount: "20.00",
    payout: "400.00",
  },
];

export const cricketItems = [
  { id: 1, label: "Cricket" },
  { id: 2, label: "Football" },
  { id: 3, label: "Basketball" },
];

export const numberItems = [
  { id: 1, label: "5" },
  { id: 2, label: "10" },
  { id: 3, label: "20" },
];

export const selectedBetItems = [
  { id: 1, label: "Settled Bet" },
  { id: 2, label: "All" },
];

export const loginIcons = [
  { id: 1, icon: insta },
  { id: 2, icon: google },
  { id: 3, icon: faceBookIcon },
  { id: 4, icon: twitter },
];

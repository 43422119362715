import { setBankAccounts } from "../redux/reducers/walletSlice";
import {
  CREDIT_WALLET,
  DEBIT_WALLET,
  ADD_BANK_ACCOUNT,
  PAY_IN,
  GET_BANK_BY_USER,
  PAY_OUT,
} from "../constants/wallet.api";

export const walletCredit = async (makeRequest, payload) => {
  makeRequest({
    url: CREDIT_WALLET,
    method: "POST",
    data: payload,
  });
};
export const walletDebit = async (makeRequest, payload) => {
  makeRequest({
    url: DEBIT_WALLET,
    method: "POST",
    data: payload,
  });
};
export const addBankAccount = async (makeRequest, payload, cb) => {
  makeRequest({
    url: ADD_BANK_ACCOUNT,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    onSuccessCallback: (res) => {
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        cb();
      }
    },
  });
};
export const paymentPayIn = async (makeRequest, payload, cb, setStep) => {
  makeRequest({
    url: PAY_IN,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    onSuccessCallback: (res) => {
      console.log('res payin', res);
      
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        cb(res?.body);
        setStep(1)
      }
    },
  });
};

export const paymentPayOut = async (makeRequest, payload, cb) => {
  makeRequest({
    url: PAY_OUT,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    isPayout:true,
    onSuccessCallback: (res) => {
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        cb();
        
      }
    },
    isToastVisible : true
  })
};

export const fetchBackAccounts = (makeRequest, dispatch) => {
  makeRequest({
    url: GET_BANK_BY_USER,
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    onSuccessCallback: (res) => {
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        console.log("res>>", res);
        dispatch(setBankAccounts(res?.body));
      }
    },
    isToastVisible: false,
    toggleLoader: false,
  });
};

import NoBetsFound from "components/common/NoBetsFound";
import React, { useEffect, useState } from "react";

const SkeletonRow = ({ columns }) => {
  return (
    <tr className="odd:bg-blackRussian even:bg-darkByzantineBlue py-[15px] h-12 rounded-xl">
      {columns.map((col, index) => (
        <td
          key={index}
          className="text-center text-sm font-medium text-table-row first:rounded-l-xl last:rounded-r-xl"
        >
          <div className="bg-gray-700 animate-pulse h-4 max-w-[75%] w-full mx-auto rounded-md"></div>
        </td>
      ))}
    </tr>
  );
};

const TableCell = ({
  isHeader,
  content,
  currency,
  render,
  alignItems,
  hideOnMobile,
}) => {
  const cellClass = `text-start px-4 ${alignItems} ${
    isHeader ? "font-semibold text-sm" : "font-medium text-sm"
  } text-table-row first:rounded-l-xl last:rounded-r-xl ${
    hideOnMobile ? "hidden sm:table-cell" : ""
  }`;

  return isHeader ? (
    <th className={cellClass}>{content}</th>
  ) : (
    <td className={cellClass}>
      {render ? (
        render(content, currency)
      ) : (
        <span className="flex items-center justify-start gap-[6px]">
          {content}
        </span>
      )}
    </td>
  );
};

const TableRow = ({ isHeader, columns, rowData }) => {
  return (
    <tr
      className={`${
        isHeader
          ? "py-5 h-[54px]"
          : "odd:bg-blackRussian even:bg-darkByzantineBlue py-[15px] h-12 rounded-xl font-medium"
      }`}
    >
      {columns.map((col, colIndex) => (
        <TableCell
          key={colIndex}
          content={isHeader ? col.header : rowData[col.key]}
          isHeader={isHeader}
          currency={rowData}
          render={col.render}
          alignItems={col.alignItems}
          hideOnMobile={col.hideOnMobile}
        />
      ))}
    </tr>
  );
};

const Table = ({ columns, data, loading }) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth < 640) {
        setVisibleColumns(columns.filter((col) => col.showOnMobile)); // Show only selected columns on mobile
      } else {
        setVisibleColumns(columns);
      }
    };

    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, [columns]);

  const skeletonRows = new Array(5).fill(null);
  return (
    <div className="bg-darkByzantineBlue px-5 py-1 pb-5 rounded-xl mt-[18px] overflow-auto">
      <table className="w-full text-vintageRibbon">
        <thead>
          <TableRow columns={visibleColumns} isHeader={true} />
        </thead>
        <tbody>
          {loading ? (
            skeletonRows.map((_, index) => (
              <SkeletonRow key={index} columns={visibleColumns} />
            ))
          ) : data?.length ? (
            data?.map((row, rowIndex) => (
              <TableRow key={rowIndex} rowData={row} columns={visibleColumns} />
            ))
          ) : (
            // <Typography
            //   color={"white"}
            //   variant={"size16Semibold"}
            //   content={"No Bets Found"}
            // />
            <tr>
              <td colSpan={visibleColumns.length}>
                <div className="flex items-center justify-center py-10">
                  <NoBetsFound title={"No Bets Found"} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

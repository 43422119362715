import React from "react";
import { BannerCard } from "./cards";
// import { bannerMb1 } from "assets"
import { useNavigate } from "react-router-dom";


const Advertisement = ({ promoBanner }) => {
  const navigate = useNavigate();

  console.log('promoBanner',promoBanner);
  

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 flex-wrap md:flex-nowrap">
      <BannerCard containerStyle={"hidden md:block"} link={promoBanner?.[0]?.link} bannerImg={promoBanner?.[0]?.imageUrl} />
      {/* <BannerCard
        containerStyle={"block md:hidden w-full"}
        bannerImg={bannerMb1}
      /> */}
      {/* <div className="flex gap-5"> */}
        <BannerCard onClick={()=>navigate('/casino-home')} bannerImg={promoBanner?.[1]?.imageUrl} />
        <BannerCard bannerImg={promoBanner?.[2]?.imageUrl} onClick={()=>navigate('/sports-home')}/>
      {/* </div> */}
    </div>
  );
};

export default Advertisement;

import React, { useState } from "react";

const LazyImage = ({
  src,
  alt,
  className,
  placeholder,
  link,
  divClassName = "h-full",
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={`relative ${divClassName}`}>
      {!isLoaded && (
        <div className="absolute inset-0 animate-pulse bg-darkByzantineBlue rounded-xl md:rounded-[20px]">
          {placeholder}
        </div>
      )}
      <a href={link}>
        <img
          src={src}
          alt={alt}
          className={`${className} h-full transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
          onLoad={() => setIsLoaded(true)}
        />
      </a>
    </div>
  );
};

export default LazyImage;

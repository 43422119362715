import React, {  useEffect, useRef, useState } from "react";
import {  closeIcon } from "assets";
import {
  Typography,
  Seperator,
  Tabs,
  Dropdown,
  BetSlipCards,
  CommonButton,
  NoBetsFound,
} from "components";
import { numberItems } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import { setBets, setBetSlipToggle } from "../../redux/reducers/dashboard";
import { placeBets } from "services/cricket.service";
import { useAxios } from "hooks";
import { checkBalance } from "services/dashboard.service";
import { useCallback } from "react";
import { getBalanceDetails } from "helper/getIcons";

const MemoizedBetSlipCards = React.memo(BetSlipCards);

const BetSlipSlider = ({ betSlipToggle }) => {
  const { bets, selectedCurrency, userBalance } = useSelector(
    (state) => state.dashboard
  );
  const { user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const betSlipSliderRef = useRef(null);
  const { makeRequest } = useAxios();
  const [betAmounts, setBetAmounts] = useState({});
  const balanceUser = userBalance?.body?.balance;
  const [lowBalanceMessage, setLowBalanceMessage] = useState(false);

  const { icon: balanceIcon, symbol: balanceSymbol } = getBalanceDetails(selectedCurrency?.label);

  const CheckBalance = useCallback(() => {
    const payload = {
      userId: user?.id,
      currency: selectedCurrency?.label,
    };
    checkBalance(makeRequest, payload, dispatch);
  }, [user?.id, dispatch, makeRequest, selectedCurrency?.label]);

  // Function to update bet amounts
  const handleBetChange = useCallback((betId, amount) => {
    setBetAmounts((prev) => ({
      ...prev,
      [betId]: amount,
    }));
  }, []);

  // Calculate total bet amount
  const totalBetAmount = Object.values(betAmounts)
    .reduce((acc, val) => acc + (val || 0), 0)
    .toFixed(2);

  // Calculate total payout correctly
  const totalPayout = Object.entries(betAmounts)
    .reduce((acc, [id, val]) => {
      const bet = bets.find((b) => b.unique_id?.toString() === id);
      const odds = bet?.sportsDetails ? 2 : bet?.odds?.value || 1; // Get odds correctly
      return acc + (val || 0) * odds; // Multiply bet amount by odds
    }, 0)
    .toFixed(2);

  useEffect(() => {
    if (containerRef.current) {
      // Scroll to the bottom of the container
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [bets]);
  
  const handleClearBets = useCallback(() => {
    dispatch(setBets([]));
    setBetAmounts({});
    setLowBalanceMessage(false);
  }, [dispatch]);
  

  useEffect(() => {
    const handleReload = () => {
      console.log("Window reloaded!");
      handleClearBets();
    };

    window.addEventListener("load", handleReload);

    return () => {
      window.removeEventListener("load", handleReload);
    };
  }, [handleClearBets]);



  const tabs = [
    { id: "single", label: "Single" },
    { id: "multi", label: "Multi" },
  ];

  const handleNumberSelect = (item) => {
    console.log("Selected Number Option:", item.label);
  };

  const handlePlaceBets = () => {
    const payload = bets?.map((item) => {
      return {
        amount: betAmounts[item?.unique_id],
        matchId: Number(item?.matchId),
        marketId: item?.marketID ?? item?.marketId,
        // selectionId: item?.selectionId?.toString(),
        odds: item?.odds,
        currencyType: selectedCurrency?.label,
        runnerID: item?.runnerId?.toString(),
      };
    });

    placeBets(makeRequest, payload, dispatch, setBetAmounts, CheckBalance);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        betSlipSliderRef.current &&
        !betSlipSliderRef.current.contains(event.target)
      ) {
        dispatch(setBetSlipToggle(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dispatch]);

  const handleFocus = (event) => {
    setTimeout(() => {
      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 300);
  };

  return (
    <aside
      // ref={betSlipSliderRef}
      style={{
        maxHeight: window.innerWidth < 1024 ? "100vh" : "calc(100vh - 72px)",
        minHeight: window.innerWidth < 1024 ? "100vh" : "calc(100vh - 72px)",
      }}
      // className={`hidden sidebar-main no-scrollbar p-4 bg-blackRussian text-white h-full md:flex flex-col overflow-auto transition-all ease-in-out duration-300 ${
      //   betSlipToggle
      //     ? "translate-x-0 min-w-[440px] w-[260px]"
      //     : "translate-x-full min-w-[0] w-0"
      // }`}
      // className={`hidden ${betSlipToggle ? 'p-4' : ''} sidebar-main no-scrollbar bg-blackRussian text-white h-full md:flex flex-col overflow-auto transition-all ease-in-out duration-300 ${betSlipToggle ? "translate-x-0 min-w-[440px] w-[260px]" : "translate-x-full min-w-[0] w-0"
      // }`}
      className={`${ window.innerWidth < 1024 ? 'top-0' : ''} z-30 lg:z-20 flex-1 absolute right-0 md:flex max-w-md bg-blackRussian sidebar-main text-white h-screen flex flex-col transition-all ease-in-out duration-300 ${
        betSlipToggle
          ? "translate-x-0 w-screen md:min-w-[350px] max-w-[27.5rem] 2xl:min-w-[27.5rem] p-4 overflow-hidden"
          : "translate-x-full min-w-[0] w-0 overflow-auto"
      }`}
    >
      {/* <div className="px-6 py-7"> */}
      <header className="">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <img
              src={closeIcon}
              alt="Close"
              className="w-3 h-3 cursor-pointer"
              onClick={() => dispatch(setBetSlipToggle(false))}
            />
            <Typography
              color={"white"}
              content={"Betslip"}
              variant={"size20Semibold"}
            />
            <span className="bg-white text-[14px] leading-4 text-blackRussian flex justify-center items-center font-semibold w-[32px] h-[24px] rounded-xl">
              {bets?.length}
            </span>
          </div>
          {/* <button className="text-primary whitespace-nowrap flex items-center font-semibold leading-4 text-sm">
            Go to my bets
            <span className="ml-3 bg-primary text-white text-sm flex justify-center items-center font-semibold w-[32px] h-[24px] rounded-xl">
              10
            </span>
          </button> */}
        </div>

        <Seperator color="ebonyClay" />
        <div className="mt-5 z-50">
          <Tabs tabs={tabs} />
        </div>

        <div className="mt-5">
          <Seperator color="ebonyClay" />
        </div>

        <div className="flex items-center justify-between mt-3">
          <Dropdown
            items={numberItems}
            onSelect={handleNumberSelect}
            placeholder="Accept Any odds"
            color="transparent"
          />
          <button
            onClick={handleClearBets}
            className="text-[14px] leading-4 font-semibold"
          >
            Clear all
          </button>
        </div>
      </header>

      {/* <div
          id="dra-456-bet-slip-space"
          ref={containerRef}
          className="mt-5 min-h-[300px] overflow-auto no-scrollbar"
        > */}
      <main
        id="dra-456-bet-slip-space"
        ref={containerRef}
        className={`flex-1 ${
          bets?.length === 0 ? "flex items-center justify-center" : null
        } overflow-y-auto overflow-hidden space-y-4 mt-5 mb-[110px] pb-24 lg:pb-20 no-scrollbar`}
      >
        {bets?.length === 0 ? (
          <NoBetsFound title={"No Active Bets"} isBetSlip />
        ) : (
          bets?.map((item, index) => (
            <MemoizedBetSlipCards
              key={index}
              isInput={true}
              data={item}
              currencySymbol={balanceSymbol}
              onBetChange={handleBetChange}
              betAmount={betAmounts[item.unique_id] || ""}
              userBalance={balanceUser}
              handleFocus={handleFocus}
              setLowBalanceMessage={setLowBalanceMessage}
            />
          ))
        )}
      </main>
      {/* </div> */}

      <footer className="absolute bottom-0 left-0 w-full text-center pb-12 lg:pb-0">
        <div className="bg-darkByzantineBlue p-6">
          <div className="flex items-center justify-between">
            <Typography
              variant="size14Normal"
              color="vintageRibbon"
              content="Total Bet Amount"
            />
            <div className="flex items-center gap-1 mt-1">
              <Typography
                variant="size14Normal"
                color="vintageRibbon"
                content={`${balanceSymbol}${totalBetAmount}`}
              />
              <img src={balanceIcon} alt="Dollar Icon" className="w-4 h-4" />
            </div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <Typography
              variant="size14Semibold"
              color="vintageRibbon"
              content="Est. Payout"
            />
            <div className="flex items-center gap-1 mt-1">
              <Typography
                variant="size14Semibold"
                color="vintageRibbon"
                content={`${balanceSymbol}${totalPayout}`}
              />
              <img src={balanceIcon} alt="Dollar Icon" className="w-4 h-4" />
            </div>
          </div>
          <div className="flex flex-col space-y-2 items-center justify-center text-white mt-4">
            {lowBalanceMessage ? (
              <div className="w-full border-2 flex items-center gap-2 justify-center border-Amaranth border-dashed py-3">
                <Typography
                  variant="size14Semibold"
                  color="Amaranth"
                  content={"You cannot bet more than your balance"}
                />
              </div>
            ) : null}
            <CommonButton
              type="viewBetsBtn"
              label={"Place Bet"}
              onClick={handlePlaceBets}
              disabled={
                bets.length === 0 ||
                Object.values(betAmounts).length !== bets.length || // Ensure all bets have amounts
                Object.values(betAmounts).some(
                  (amount) => !amount || amount <= 0,
                ) || // Check for missing or zero amounts
                lowBalanceMessage
              }
            />
            {/* <span className="ml-3 bg-white text-primary text-sm flex justify-center items-center font-semibold w-[32px] h-[24px] rounded-xl">10</span> */}
          </div>
        </div>
      </footer>
      {/* <div className="block lg:hidden absolute bottom-[-30px] left-0 w-full text-center p-12 bg-darkByzantineBlue" /> */}
      {/* </div> */}
    </aside>
  );
};

export default BetSlipSlider;

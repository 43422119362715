const LOGIN = "auth/login"
const REGISTER = "auth/register"
const OTP_VERIFY = "email/verify-email"
const RESET_PASSWORD = "email/reset-password"
const CHANGE_PASSWORD = "password/change-password"
const FORGOT_PASSWORD = "email/forgot-password"

export {
    LOGIN,
    REGISTER,
    OTP_VERIFY,
    RESET_PASSWORD,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD
}
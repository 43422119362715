import accordionMinusIcon from "./svg/accordion-minus-icon.svg"
import accordionPlusIcon from "./svg/accordion-plus-icon.svg"
import arrowDown from "./svg/arrow-down.svg"
import backIcon from "./svg/back.svg"
// import bannerMb1 from "./svg/banner-mb-1.svg"
import betSlipEmpty from "./svg/bet-slip-empty.svg"
import btcIcon from "./svg/btc.svg"
import euro from "./svg/euro.svg"
import btc2Icon from "./svg/btc2.svg"
import casionIcon from "./svg/casino-icon.svg"
import closeIcon from "./svg/close.svg"
import copy from "./svg/copy.svg"
import correctIcon from "./svg/correctIcon.svg"
import dropdownArrow from "./svg/dropdown-arrow.svg"
import ethIcon from "./svg/eth.svg"
import eyeIcon from "./svg/eye.svg"
import faceBookIcon from "./svg/facebook.svg"
import failed from "./svg/failed.svg"
import globe from "./svg/globe.svg"
import google from "./svg/google.svg"
import home from "./svg/home.svg"
import infoIcon from "./svg/info-icon.svg"
import insta from "./svg/insta.png"
import leftArrow from "./svg/left-arrow.svg"
import logoStarIcon from "./svg/logo-star-icon.svg"
import mbSearch from "./svg/mb-search.svg"
import mbDepositSocialImg from "./svg/mbDepositeSocialImg.png"
import menuIcon from "./svg/menu-icon.svg"
import noBetFound from "./svg/no-bet-found.svg"
import paymentDone from "./svg/payment-done.svg"
import paymentFail from "./svg/payment-fail.svg"
import profileIcon from "./svg/profile-icon.svg"
import qrCode from "./svg/qr.svg"
import reload from "./svg/reload.svg"
import rightArrow from "./svg/right-arrow.svg"
import ripple from "./svg/ripple.svg"
import rupees from "./svg/rupees.svg"
import searchIcon from "./svg/searchIcon.svg"
import setting from "./svg/settings.svg"
import sportsIcon from "./svg/sports-icon.svg" 
import star from "./svg/star.svg"
import toasterCheck from "./svg/toasterCheck.svg"
import toasterClose from "./svg/toasterCloseIcon.svg"
import toasterFail from "./svg/toasterFailIcon.svg"
import toasterSlip from "./svg/toasterSlipIcon.svg"
import twitter from "./svg/twitter.svg"
import usdIcon from "./svg/usd-icon.svg"
import usd from "./svg/usd.svg"
import waiting from "./svg/waiting.svg"
import sliderLeftArrow from "./svg/slider-left-arrow.svg"
import rightDisabled from "./svg/rightDisabled.svg"
import fullScreenIcon from "./svg/full-screen-icon.svg"
import playIcon from "./svg/play-icon.svg"
import blackPlayIcon from "./svg/black-play-icon.svg"
import indianFlag from "./svg/indiaFlag.svg"
import nzFlag from "./svg/NZFlag.svg"
import zafFlag from "./svg/zafFlag.svg"
import auzFlag from "./svg/auzFlag.svg"
import gbrFlag from "./gbr-flag.webp"
import odds777 from "./odds-777.png"
import wallet from "./wallet.png"
import logoIcon from "./logo-icon.png"
import logoGif from "./Loader Q.gif"
import globalLoader from "./PreLoader.gif"
import pkrQr from "./pkrScanner.jpeg"
import inrQr from "./inrScan.jpeg"
import aedIcon from "./aedIcon.png"
import eyeOffIcon from "./svg/eye-off.svg"

export {
    eyeOffIcon,
    pkrQr,
    inrQr,
    fullScreenIcon,
    odds777,
    logoIcon,
    logoGif,
    globalLoader,
    playIcon,
    blackPlayIcon,
    accordionMinusIcon,
    accordionPlusIcon,
    waiting,
    usd,
    euro,
    aedIcon,
    usdIcon,
    twitter,
    toasterCheck,
    toasterClose,
    toasterFail,
    toasterSlip,
    mbDepositSocialImg,
    betSlipEmpty,
    noBetFound,
    backIcon,
    // bannerMb1,
    arrowDown,
    leftArrow,
    rightDisabled,
    dropdownArrow,
    btc2Icon,
    btcIcon,
    ethIcon,
    eyeIcon,
    paymentDone,
    paymentFail,
    faceBookIcon,
    failed,
    infoIcon,
    insta,
    casionIcon,
    closeIcon,
    copy,
    correctIcon,
    globe,
    google,
    home,
    logoStarIcon,
    mbSearch,
    menuIcon,
    profileIcon,
    qrCode,
    reload,
    ripple,
    rupees,
    rightArrow,
    searchIcon,
    setting,
    sportsIcon,
    star,
    sliderLeftArrow,
    nzFlag,
    indianFlag,
    zafFlag,
    auzFlag,
    gbrFlag,
    wallet
}
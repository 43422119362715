import { CHECK_BALANCE, GAME_ENTRY, LAYOUT } from "constants/wallet.api";
import {
  setLayoutData,
  setSidebarData,
  setUserBalance,
} from "../redux/reducers/dashboard";

export const fetchLayout = async (makeRequest, param, dispatch) => {
  makeRequest({
    url: `${LAYOUT}?page-id=${param}`,
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    reduxAction: setLayoutData,
    onSuccessCallback: (res) => {      
      if (param === "HOME") {
        dispatch(setSidebarData(res?.layout));
      }
    },
    isToastVisible: false,
  });
};

export const checkBalance = async (makeRequest, payload) => {
  makeRequest({
    url: CHECK_BALANCE,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    reduxAction: setUserBalance,
    isToastVisible: false,
    toggleLoader: false,
  });
};
export const gameEntry = async (makeRequest, payload, setIframeUrl) => {
  makeRequest({
    url: GAME_ENTRY,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    onSuccessCallback: (res) => {
      // console.log('res>>>>>>>>>>>',res?.body?.entry);
      setIframeUrl(res?.body?.entry);
    },
    isToastVisible: false,
  });
};

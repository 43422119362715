import { createSlice } from "@reduxjs/toolkit";

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    bankAccounts: [],
    upiAmount: null
  },
  reducers: {
    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload;
    },
    setUpiAmount: (state, action) => {
      state.upiAmount = action.payload;
    }
  },
});

export const { setBankAccounts, setUpiAmount } = walletSlice.actions;
export default walletSlice.reducer;

import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = window.innerWidth < 640 ? 3 : 5;
  let pages = [];

  const adjustedCurrentPage = currentPage + 1;
  const adjustedTotalPages = totalPages;

  if (adjustedTotalPages <= maxPagesToShow) {
    pages = Array.from({ length: adjustedTotalPages }, (_, i) => i + 1);
  } else {
    if (adjustedCurrentPage <= 3) {
      pages = [1, 2, 3, "...", adjustedTotalPages];
    } else if (adjustedCurrentPage >= adjustedTotalPages - 2) {
      pages = [1, "...", adjustedTotalPages - 2, adjustedTotalPages - 1, adjustedTotalPages];
    } else {
      pages = [1, "...", adjustedCurrentPage - 1, adjustedCurrentPage, adjustedCurrentPage + 1, "...", adjustedTotalPages];
    }
  }

  return (
    <div className="space-x-1 sm:space-x-2 py-4 flex-wrap">
      {/* Back Button */}
      <button
        className={`px-3 py-2 sm:px-4 sm:py-3 rounded-md text-sm font-semibold ${
          currentPage === 0 ? "cursor-not-allowed bg-ebonyClay text-vintageRibbon" : "bg-ebonyClay text-vintageRibbon hover:border hover:border-primary hover:text-primary hover:bg-russianViolet"
        }`}
        disabled={currentPage === 0}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Back
      </button>

      {/* Page Numbers */}
      {pages.map((page, index) => (
        <button
          key={index}
          className={`px-3 py-2 sm:px-4 sm:py-3 rounded-md font-semibold text-sm ${
            adjustedCurrentPage === page
              ? "border border-white text-vintageRibbon bg-ebonyClay"
              : "bg-ebonyClay text-vintageRibbon hover:bg-russianViolet hover:border hover:border-primary hover:text-primary"
          } ${page === "..." ? "cursor-default" : ""}`}
          onClick={() => page !== "..." && onPageChange(page - 1)}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}

      {/* Next Button */}
      <button
        className={`px-3 py-2 sm:px-4 sm:py-3 rounded-md text-sm font-semibold ${
          currentPage === adjustedTotalPages ? "cursor-not-allowed bg-ebonyClay text-vintageRibbon" : "bg-ebonyClay text-vintageRibbon hover:border hover:border-primary hover:text-primary hover:bg-russianViolet"
        }`}
        disabled={currentPage === adjustedTotalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

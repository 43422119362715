const CREDIT_WALLET = "wallet/credit"
const DEBIT_WALLET = "wallet/debit"
const ADD_BANK_ACCOUNT = "wallet/add-bank"
const PAY_IN = "api/payment/payin"
const PAY_OUT = "api/withdraw/payout"
const GET_BANK_BY_USER = "wallet/get-bank-by-user"
const GAME_ENTRY = "wallet/game-entry"
const CHECK_BALANCE = "wallet/check-balance"
const LAYOUT = "api/layout"


export {
    CREDIT_WALLET,
    DEBIT_WALLET,
    ADD_BANK_ACCOUNT,
    PAY_IN,
    PAY_OUT,
    GET_BANK_BY_USER,
    CHECK_BALANCE,
    LAYOUT,
    GAME_ENTRY
}
import React from "react";
import { BannerCard } from "./cards";

const Advertisement = ({ promoBanner }) => {

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 flex-wrap md:flex-nowrap">
      <BannerCard containerStyle={"hidden md:block"} link={promoBanner?.[0]?.link} bannerImg={promoBanner?.[0]?.imageUrl} imgStyle={"object-cover"} />
      {/* <BannerCard
        containerStyle={"block md:hidden w-full"}
        bannerImg={bannerMb1}
      /> */}
      {/* <div className="flex gap-5"> */}
        <BannerCard link={promoBanner?.[1]?.link} bannerImg={promoBanner?.[1]?.imageUrl} imgStyle={"object-cover"}  />
        <BannerCard bannerImg={promoBanner?.[2]?.imageUrl} link={promoBanner?.[2]?.link} imgStyle={"object-cover"}/>
      {/* </div> */}
    </div>
  );
};

export default Advertisement;

import { setSelectedCurrency } from "../redux/reducers/dashboard";
import { LOGIN, OTP_VERIFY, REGISTER } from "../constants/auth.api";
import { login, setRegisterPayload } from "../redux/reducers/authSlice";

export const loginFunc = async (
  makeRequest,
  payload,
  closeModal,
  dispatch,
  icons
) => {
  makeRequest({
    url: LOGIN,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    reduxAction: login,
    onSuccessCallback: (res) => {
      const formattedCurrencies = res?.body?.walletCurrencies.map(
        (currency, index) => ({
          id: index + 1,
          label: currency,
          icon: icons[currency] || "defaultIcon", // Use a default icon if not found
          value: 0,
        })
      );
      const defaultCurrency = formattedCurrencies?.find(
        (i) => i?.label === "INR"
      );
      dispatch(setSelectedCurrency(defaultCurrency));
      closeModal();
    },
  });
};


export const registerFunc = async (
  makeRequest,
  payload,
  closeModal,
  dispatch
) => {
  makeRequest({
    url: REGISTER,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      clientKey: "QXNoaXNo",
      clientId: "QXNoaXNo",
    },
    data: payload,
    onSuccessCallback: (res) => {
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        dispatch(setRegisterPayload(payload));
        closeModal();
      }
    },
  });
};

export const otpVerify = async (
  makeRequest,
  email,
  otp,
  closeModal,
  dispatch
) => {
  makeRequest({
    url: `${OTP_VERIFY}?email=${email}&token=${otp}`,
    method: "GET",
    onSuccessCallback: (res) => {
      if (res?.responseCode === "200" || res?.responseCode === "201") {
        dispatch(setRegisterPayload(null));
        closeModal();
      }
    },
  });
};
